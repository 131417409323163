<script>
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment'
import reportsMixin from '@/modules/Reports/reportsMixin'

export default {
  name: 'ReportCommentsList',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ reportsMixin ],
  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: '',
        solutionType: null,
        status: null,
        grade: [],
        solutions: [],
        programs: [],
        survey: null,
        reportId: null
      },

      filteredFormLabels: {
        status: [
          {
            text: this.$t('comments:status.approved'),
            value: 'approved'
          },
          {
            text: this.$t('comments:status.disapproved'),
            value: 'disapproved'
          }
        ],

        grade: [
          {
            text: 1,
            value: 1
          },
          {
            text: 2,
            value: 2
          },
          {
            text: 3,
            value: 3
          },
          {
            text: 4,
            value: 4
          },
          {
            text: 5,
            value: 5
          }
        ]
      },

      foundSurveys: null,
      toolTypes: [
        {
          text: 'Solução',
          value: 'solution'
        },
        {
          text: 'Programa',
          value: 'program',
          module: 'program'
        }
      ],

      toolTypeSelected: null,
      allProgramsList: [],
      allSolutionsList: [],
      modalWarning: false
    }
  },

  validations: {
    toolTypeSelected: {
      required
    },

    formData: {
      solutions: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'solution'
        })
      },

      programs: {
        required: requiredIf(function () {
          return this.toolTypeSelected === 'program'
        })
      },

      grade: {
        required
      },

      status: {
        required
      },

      dateInit: {
        required
      },

      dateEnd: {
        required
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),

    isProgramEnabled () {
      return this.activeModules.includes('program')
    },
  },

  created () {
    this.setFetching(true)

    this.toolTypes = this.toolTypes.filter((item) => {
      return item.module === undefined || this.activeModules.includes(item.module)
    })

    if (this.isProgramEnabled) {
      this.fetchSolutionsAndPrograms()
    } else {
      this.fetchSolutions()
    }
    
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsList',
      'attemptGetProgramsList',
      'attemptGetReportListComments',
      'attemptReportDownload',
      'attemptCheckReport'
    ]),

    async fetchSolutionsAndPrograms () {
      try {
        const [
          solutionsResponse,
          programsResponse
        ] = await Promise.all([
          this.attemptGetSolutionsList({
            order: { name: 'ASC' },
            limit: 9999,
            page: 1
          }),
          this.attemptGetProgramsList({
            order: { name: 'ASC' },
            limit: 9999,
            page: 1
          })
        ])

        this.allSolutionsList = solutionsResponse.data.map(element => ({
          text: element.name,
          value: element.id
        }))

        this.allProgramsList = programsResponse.data.map(element => ({
          text: element.name,
          value: element.id
        }))
      } catch (error) {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      } finally {
        this.setFetching(false)
      }
    },

    async fetchSolutions () {
      try {
        const [
          solutionsResponse
        ] = await Promise.all([
          this.attemptGetSolutionsList({
            order: { name: 'ASC' },
            limit: 9999,
            page: 1
          })
        ])

        this.allSolutionsList = solutionsResponse.data.map(element => ({
          text: element.name,
          value: element.id
        }))
      } catch (error) {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      } finally {
        this.setFetching(false)
      }
    },

    getReportCommentsList () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const initDate = moment(this.formData.dateInit)
      const finalDate = moment(this.formData.dateEnd)

      if (finalDate.diff(initDate, 'days') > 31) {
        this.modalWarning = true
      } else {
        this.generateReport()
      }
    },

    generateReport () {
      this.modalWarning = false
      const params = {
        createdDateInit: this.formData.dateInit,
        createdDateEnd: this.formData.dateEnd,
        solutionIds: this.formData.solutions,
        programIds: this.formData.programs,
        grade: this.formData.grade,
        status: this.formData.status
      }

      this.setFetching(true)

      this.attemptGetReportListComments(params).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    closeModal () {
      this.modalWarning = false
    },

    leave () {
      this.$router.push({ name: 'reports.index' })
    },

    async downloadReport () {
      try {
        const response = await this.attemptReportDownload(this.reportId)
        const url = window.URL.createObjectURL(new Blob([ response.data ]))
        const link = document.createElement('a')

        link.href = url

        link.setAttribute(
          'download',
          this.$t('reports.generate:report.comments.list.file.name').toString()
        )

        document.body.appendChild(link)
        link.click()
      } catch (error) {
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      }
    },

    async checkReport () {
      try {
        while (true) {
          const response = await this.attemptCheckReport(this.reportId)

          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            break
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            break
          }

          await new Promise(resolve => setTimeout(resolve, 5000))
        }
      } catch (error) {
        this.setFetching(false)
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      }
    }
  }
}
</script>

<template>
  <div class="main-content reports-create">
    <ContentHeader
      :title="$t('reports.generate:report.comments.list.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>
        <form class="form">
          <FormSection :title="$t('global:form.filters')">
            <SelectField
              v-model="toolTypeSelected"
              :label="$t('global:form.survey.tool.type')"
              :items="toolTypes"
              :validation="$v.toolTypeSelected"
            />
            <div v-if="toolTypeSelected">
              <SelectField
                v-if="toolTypeSelected === 'solution'"
                v-model="formData.solutions"
                :label="$t('global:form.solution')"
                :items="allSolutionsList"
                :validation="$v.formData.solutions"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <SelectField
                v-if="toolTypeSelected === 'program'"
                v-model="formData.programs"
                :label="$t('global:menu.programs')"
                :items="allProgramsList"
                :validation="$v.formData.programs"
                multiple
                :select-all-option="true"
                :searcheable="true"
              />
              <SelectField
                v-model="formData.status"
                :label="$t('comments:datatable.header.6')"
                :items="filteredFormLabels.status"
                :validation="$v.formData.status"
              />
              <SelectField
                v-model="formData.grade"
                :label="$t('comments:label.grade')"
                multiple
                :items="filteredFormLabels.grade"
                :validation="$v.formData.grade"
                :select-all-option="true"
              />
              <Datepicker
                v-model="formData.dateInit"
                :label="$t('global:form.filters.date.init')"
                :validation="$v.formData.dateInit"
              />
              <Datepicker
                v-model="formData.dateEnd"
                :label="$t('global:form.filters.date.end')"
                :validation="$v.formData.dateEnd"
              />
            </div>
          </FormSection>
          <Action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportCommentsList"
          />
        </form>
      </div>
    </div>
    <ModalConfirm
      :active="modalWarning"
      :title="$t('reports.generate:report.comments.list.title')"
      :description="$t('reports.generate:report.modal.description')"
      @cancelAction="closeModal"
      @confirmAction="generateReport"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>
